import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthProvider';
import moment from 'moment';
import OltTypeSelect from '../../../components/OltSTypeSelect';
import OltProxySelect from '../../../components/OltProxySelect';
import FormOltAccess from './formOltAccess';
import OltSelect from '../../../components/OltSelect';
import CustomTextField from '../../../components/CustomTextField';

export default function Profile() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Onu's - Profile"
       
          error.setErrors([])
       
    }, [])
    return (
        <>  <FormOltAccess open={formAccess.open} olt={formAccess.id}
            empresa= {formAccess.empresa}
            oltName= {formAccess.olt_name}
            handlerClose={() => setFormAccess({ open: false, id: null })}
            handlerSubmit={false}
        />
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Profiles'
                endpoint='/provisioning/profile'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('profile.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_id',
                        label: 'empresa_id',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "description",
                        label: "Nome",
                    },
                    {
                        name: 'vlan',
                        label: 'Vlan',
                    },
                    {
                        name: 'gemport',
                        label: 'Gemport',
                    },
                    {
                        name: 'olt_serv_profile_id',
                        label: 'OLT Service Profile',
                    },
                    {
                        name: 'olt_line_profile_id',
                        label: 'OLT Line Profile',
                    },
                    {
                        name: 'active',
                        label: 'Ativo',
                        options: {
                            customBodyRender: (value) => value == 1 ? 'Sim' : 'Não'
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('profile.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Acessos">
                                            <IconButton disabled={!auth.hasPerm('profile.update')}
                                                onClick={() => setFormAccess({ open: true, id: tableMeta.rowData[0],
                                                    empresa: tableMeta.rowData[1],
                                                    olt_name: tableMeta.rowData[3] + " - " +tableMeta.rowData[4],


                                                 })}
                                            >
                                                <KeyIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        description: '',
        vlan: '',
        olt_serv_profile_id: '',
        olt_line_profile_id: '',
        active: 1,
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/provisioning/profile', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])
    


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/provisioning/profile', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Profile atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/provisioning/profile/create', state)
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Profile criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
                }
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Profile</Typography>
                        <IconButton disabled={!auth.hasPerm("profile.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={() => {
                            if (confirm('Deseja excluir o Profile?')) {
                                delete_one('/provisioning/profile', id).then(res => {
                                    notify.setNotify({ open: true, message: 'Profile deletado com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Nome'
                        value={state.description}
                        name={'description'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('vlan')}
                        helperText={error.msgError('vlan')}
                        label='Vlan'
                        value={state.vlan}
                        name={'vlan'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('gemport')}
                        helperText={error.msgError('gemport')}
                        label='Gemport'
                        value={state.gemport}
                        name={'gemport'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('olt_serv_profile_id')}
                        helperText={error.msgError('olt_serv_profile_id')}
                        label='OLT Service Profile'
                        value={state.olt_serv_profile_id}
                        name={'olt_serv_profile_id'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('olt_line_profile_id')}
                        helperText={error.msgError('olt_line_profile_id')}
                        label='OLT Line Profile'
                        value={state.olt_line_profile_id}
                        name={'olt_line_profile_id'}
                        onChange={handleChange}
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                checked={state?.active}
                                onChange={(e) => setState({ ...state, active: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
