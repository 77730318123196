import React from "react";

import { Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "../components/AuthProvider";


import Role from '../pages/user/role';

import User from '../pages/user/user';
import OnusProv from '../pages/provisioning/prov_service/onusSearch';
import Dashboard from '../pages/provisioning/dashboard';


import ProvOltUser from '../pages/provisioning/prov_service/olts';
import CTO from '../pages/provisioning/prov_service/Cto';
import Profile from '../pages/provisioning/prov_service/Profile';
import ProvAdd from '../pages/provisioning/prov_service/ProvAdd';
import ProvRem from '../pages/provisioning/prov_service/ProvRem';
import Map from '../pages/provisioning/prov_service/Map/Map';
import ONU from '../pages/provisioning/prov_service/Onus';
import LogAuditClient from '../pages/provisioning/logAudit';
import MainFrame from '../components/MainFrame';
import NotAuthorized from "../pages/NotAuthorized";
import NotFound from "../pages/NotFound";
import LoginAdminPage from '../pages/admin/login/LoginPage';
import Recover from '../pages/provisioning/recovery';
import ChangePassword from '../pages/provisioning/recovery/changePassword'
import {Menu} from '../pages/provisioning/Menu';
import OnuLogo from '../components/imgs/onusLogo.png';
export default function ProvisioningRoutes() {
    return (
        <>
            <Route path="/provisioning" element={
                <RequireAuth service={"provisioning"}>
                  <MainFrame
                  logo={OnuLogo}
                  menuComponent={<Menu/>}
                  />
                </RequireAuth>
            }>
                <Route path="" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="client" element={<OnusProv />} />
                <Route path="olt" element={<ProvOltUser />} />
                <Route path="cto" element={<CTO />} />
                <Route path="profile" element={<Profile />} />
                <Route path="add_onu" element={<ProvAdd />} />
                <Route path="rem_onu" element={<ProvRem />} />
                <Route path="onu" element={<ONU />} />
                <Route path="map" element={<Map />} />
                <Route path="audit" element={<LogAuditClient />} />
                <Route path="role" element={<Role />} />
                <Route path="user" element={<User />} />
                <Route path="403" element={<NotAuthorized />} />
                <Route path="*" element={<NotFound />} />
                <Route path="role" element={<Role />} />
                <Route path="user" element={<User />} />
            </Route>
        </>

    )
}