import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthAdminProvider';
import EmpresaSelect from '../../components/EmpresaSelect';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';

export default function LogAuditAdmin() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Log Audit"
      }, [])
    return (
        <>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Log Auditoria'
                endpoint='/admin/audit'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('empresa.create')}
                                    onClick={() => setFormState({ open: true, id: null })} 
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip> */}
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "username",
                        label: "Usuário",
                    },
                    {
                        name: 'user_id',
                        label: 'ID do usuário',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa',
                        label: 'Empresa',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'action',
                        label: 'Ação',
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    // {
                    //     name: 'updated_at',
                    //     label: 'Atualizado em',
                    //     options: {
                    //         customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                    //     }
                    // },
                    // {
                    //     name: 'actions',
                    //     label: 'Ações',
                    //     options: {
                    //         filter: false,
                    //         sort: false,
                    //         customBodyRender: (value, tableMeta, updateValue) => {
                    //             return (
                    //                 <>
                    //                     <Tooltip title="Editar">
                    //                         <IconButton disabled={!auth.hasPerm('empresa.update')}
                    //                             onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                    //                         >
                    //                             <EditIcon />

                    //                         </IconButton>
                    //                     </Tooltip>

                    //                 </>
                    //             )
                    //         }
                    //     }
                    // }
                ]}

            />
        </>
    )
}



