import * as React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import InboxIcon from '@mui/icons-material/Inbox';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ComputerIcon from '@mui/icons-material/Computer';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import { Divider, List } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DescriptionIcon from '@mui/icons-material/Description';
import { BarChart, Description, Lan, LanOutlined, Subscriptions } from '@mui/icons-material';
import { useAuth } from '../../components/AuthProvider';
import Config from './Config';

export const Menu =({open})=>{
  const auth = useAuth();
  return (
    <List>
  
        <ItemMenu icon={<DashboardIcon />}
          name="Dashboard" path="/hermes"/>
      <ItemMenu icon={<LanOutlined />} name="Hosts" path="/hermes/host"  />
      <ItemMenu icon={<Description />} name="Backups" path="/hermes/backup"  />
      <ItemMenu icon={<BarChart />} name="Relatório de Erros" path="/hermes/error-backup"  />
      <ItemMenu icon={<Subscriptions />} name="Fila" path="/hermes/queue"  />
     <Divider/>
      <ItemMenu icon={<BarChart />} name="Log Auditoria" path="/hermes/audit"  />
      <Config/>
     {auth.hasPerm("role.get_all") && auth.hasPerm("user.get_all") &&
      <ListItemMenu icon={<SettingsIcon />} name={"Config"}>
        <ItemMenu icon={<GroupIcon />}
          name="Cargos" path="/hermes/role" perm="role.get_all" />
        <ItemMenu icon={<PersonIcon />}
          name="Usuários" path="/hermes/user" perm="user.get_all" />
      </ListItemMenu>}
    </List>
  )
};

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
