import React from "react";

import { Route, Routes } from "react-router-dom";

import RoleAccess from '../pages/admin/access/role';
import UserAccess from '../pages/admin/access/user';
import TunnelGre from '../pages/admin/prov_service/TunnelGRE'
import RoleAdmin from '../pages/admin/user/role';
import UserAdmin from '../pages/admin/user/user';
import Empresa from '../pages/admin/empresa';
import ProvService from '../pages/admin/prov_service/services';
import ProvOlt from '../pages/admin/prov_service/olts';
import ProvProxies from '../pages/admin/prov_service/proxies';

import Log from '../pages/admin/prov_service/Log';
import LogAuditAdmin from '../pages/admin/logAudit';
import { RequireAuthAdmin } from "../components/AuthAdminProvider";
import NotFound from "../pages/NotFound";
import NotAuthorized from "../pages/NotAuthorized";
import MainFrame from "../components/MainFrame";
import BackupService from "../pages/admin/backup_service/services";
import { MenuAdmin } from '../pages/admin/Menu';

export default function AdminRoutes() {
    return (
        <>
            <Route path="/admin" element={
                <RequireAuthAdmin>
                    <MainFrame admin
                    menuComponent={<MenuAdmin/>}
                    />
                </RequireAuthAdmin>
            }>

                <Route path="/admin" element={<Empresa />} />
                <Route path="/admin/user" element={<UserAdmin />} />
                <Route path="/admin/user/role" element={<RoleAdmin />} />
                <Route path="/admin/access/role" element={<RoleAccess />} />
                <Route path="/admin/provisioning/log" element={<Log />} />
                <Route path="/admin/access/role" element={<RoleAccess />} />
                <Route path="/admin/access/user" element={<UserAccess />} />
                <Route path="/admin/empresa" element={<Empresa />} />
                <Route path="/admin/provisioning/service" element={<ProvService />} />
                <Route path="/admin/provisioning/olt" element={<ProvOlt />} />
                <Route path="/admin/provisioning/proxy" element={<ProvProxies />} />
                <Route path='/admin/audit' element={<LogAuditAdmin />} />
                <Route path="/admin/provisioning/gre" element={<TunnelGre />} />
                <Route path="/admin/hermes/service" element={<BackupService />} />

                <Route path="*" element={<NotFound />} />
                <Route path="403" element={<NotAuthorized />} />

            </Route>
        </>
    )
}