import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Box,  Checkbox, FormControlLabel, FormGroup, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';
import EmpresaSelect from '../../../components/EmpresaSelect';
import OltTypeSelect from '../../../components/OltSTypeSelect';
import OltProxySelect from '../../../components/OltProxySelect';
import CustomTextField from '../../../components/CustomTextField';

export default function ProxyService() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Proxies"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Proxy Olts'
                endpoint='/admin/provisioning/proxy'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('proxies.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: "name",
                        label: "Nome",
                    },
                    {
                        name: 'ip',
                        label: 'IP',
                    },
                    {
                        name: 'port',
                        label: 'Porta',
                    },
                    {
                        name: 'interface',
                        label: 'Interface',
                    },
                    {
                        name: 'max_access',
                        label: 'Acessos Simultâneos',
                    },

                    {
                        name: 'active',
                        label: 'Ativo',
                        options: {
                            customBodyRender: (value) => value == 1 ? 'Sim' : 'Não'
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('proxies.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {name: '',
    ip: '',
    username: '',
    password: '',
    active: 1,
    port: '',
    interface: '',
    max_access: 10,
    empresa: null,
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/provisioning/proxy', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/admin/provisioning/proxy', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Proxy atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/provisioning/proxy/create', state)
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Proxy criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Proxy</Typography>
                        <IconButton disabled={!auth.hasPerm("proxies.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={() => {
                            if (confirm('Deseja excluir o proxy?')) {
                                delete_one('/admin/provisioning/proxy', id).then(res => {
                                    notify.setNotify({ open: true, message: 'Proxy deletado com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <EmpresaSelect
                        disabled={id}
                        value={state.empresa}
                        handler={(e) => setState({ ...state, empresa: e })}
                    />
                   
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('name')}
                        helperText={error.msgError('name')}
                        label='Nome'
                        value={state.name}
                        name={'name'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuario'
                        value={state.username}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('ip')}
                        helperText={error.msgError('ip')}
                        label='IP'
                        value={state.ip}
                        name={'ip'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state.password}
                        name={'password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        value={state.port}
                        name={'port'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('interface')}
                        helperText={error.msgError('interface')}
                        label='Interface'
                        value={state.interface}
                        name={'interface'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('max_access')}
                        helperText={error.msgError('max_access')}
                        label='Acessos Simultâneos'
                        value={state.max_access}
                        name={'max_access'}
                        onChange={handleChange}
                    />

                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                            checked={state?.active}
                                onChange={(e) => setState({ ...state, active: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
