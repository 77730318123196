import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { Close, Done, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';
import HermesTypeSelect from '../../components/HermesTypeSelect';
import TunnelSelect from '../../components/TunnelSelect';

export default function LogAudit() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Hermes - Logs de Auditoria"
          error.setErrors([])
    }, [])
    return (
        <> 
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)                    
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Logs de Auditoria'
                endpoint='/hermes/audit'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: "ID",
                        options: {
                          filter: false,
                          sort: false,
                          display: false
                        }
                      },
                      {
                        name: "username",
                        label: 'Usuário',
                        options: {
                          filter: true,
                          sort: false,
                        }
                      },
                      {
                        name: "message",
                        label: 'Mensagem',
                        options: {
                          filter: true,
                          sort: false,
                        }
                      },
                      {
                        name: "created_at",
                        label: "Data de Criação",
                        options: {
                          customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                          filter: true,
                          sort: false,
                        }
                      },
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        host_name: '',
        type_vendor: null,
        ip_address: '',
        username: '',
        password: '',
        port: '',
        status: 1,
        

    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/hermes/host', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])
    


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/hermes/host', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        console.log(res.data.errors)
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/hermes/host/create', state)
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
                }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Host</Typography>
                        <IconButton disabled={!auth.hasPerm("host.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={() => {
                            if (confirm('Deseja excluir o Host?')) {
                                delete_one('/hermes/host', id).then(res => {
                                    notify.setNotify({ open: true, message: 'Host deletado com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pt={1} gap={1}>
                    <CustomTextField

                        error={error.verify('host_name')}
                        helperText={error.msgError('host_name')}
                        label='Nome do Host'
                        value={state?.host_name}
                        name={'host_name'}
                        onChange={handleChange}
                    />
                   <HermesTypeSelect
                    fullWidth
                    handler={(type) => setState(prev => ({ ...prev, type }))}
                    value={state?.type}
                    size="small"
                   />
                    <CustomTextField
                        
                        error={error.verify('ip_address')}
                        helperText={error.msgError('ip_address')}
                        label='IP'
                        value={state?.ip_address}
                        name={'ip_address'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        value={state?.username}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state?.password}
                        name={'password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('enable_password')}
                        helperText={error.msgError('enable_password')}
                        label='Enable Password'
                        value={state?.enable_password}
                        name={'enable_password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        value={state?.port}
                        name={'port'}
                        onChange={handleChange}
                    />
                    <TunnelSelect
                    fullWidth
                    admin={false}
                    handler={(tunnel) => setState(prev => ({ ...prev, interface: tunnel }))}
                    value={state?.interface}
                    size="small"
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                checked={state?.status }
                                onChange={(e) => setState({ ...state, status: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
