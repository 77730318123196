import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { Refresh, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';
import EmpresaSelect from '../../../components/EmpresaSelect';
import OltTypeSelect from '../../../components/OltSTypeSelect';
import OltProxySelect from '../../../components/OltProxySelect';
import FormOltAccess from './formOltAccess';
import { Form } from 'react-router-dom';
import CustomTextField from '../../../components/CustomTextField';
import TunnelSelect from '../../../components/TunnelSelect';
import { useLoading } from '../../../components/LoadingProvider';

export default function ProvService() {
    const notify = useNotify()
    const auth = useAuth()
    const loading = useLoading()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Olts"
    }, [])
    return (
        <>  <FormOltAccess open={formAccess.open} olt={formAccess.id}
            empresa= {formAccess.empresa}
            oltName= {formAccess.olt_name}
            handlerClose={() => setFormAccess({ open: false, id: null })}
            handlerSubmit={false}
        />
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Olts'
                endpoint='/admin/provisioning/olt'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('olt.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_id',
                        label: 'empresa_id',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: "name",
                        label: "Nome",
                    },
                    {
                        name: 'ip',
                        label: 'IP',
                    },
                    {
                        name: 'port',
                        label: 'Porta',
                    },
                    {
                        name: 'interface',
                        label: 'Interface',
                    },
                    {
                        name: 'type_name',
                        label: 'Tipo',
                    },
                    {
                        name: 'proxy_name',
                        label: 'Proxy',
                    },

                    {
                        name: 'active',
                        label: 'Ativo',
                        options: {
                            customBodyRender: (value) => value == 1 ? 'Sim' : 'Não'
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('olt.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Acessos">
                                            <IconButton disabled={!auth.hasPerm('olt.update')}
                                                onClick={() => setFormAccess({ open: true, id: tableMeta.rowData[0],
                                                    empresa: tableMeta.rowData[1],
                                                    olt_name: tableMeta.rowData[3] + " - " +tableMeta.rowData[4],


                                                 })}
                                            >
                                                <KeyIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Sincronizar dados">
                                             <IconButton disabled={!auth.hasPerm('olt.update')}
                                                 onClick={() => {
                                                     const id = tableMeta.rowData[0]
                                                     if (!confirm("Tem certeza que deseja sincronizar os dados? ESSA OPERAÇÃO CONSOME BASTANTE TEMPO.")) return
                                                     loading.setLoading(true)
                                                     api.get("/admin/provisioning/olt/sync/"+id).then(res=>{
                                                             if (res.data.error) {
                                                                 notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                                                                 return
                                                                 }
                                                              
                                                         }
                                                     ).finally(()=> loading.setLoading(false))
                                                 }}
                                             >
                                                 <Refresh />

                                             </IconButton>
                                         </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {name: '',
    ip: '',
    port: '',
    interface: '',
    type: '',
    proxy: null,
    active: 1,
    empresa: null,
    monitoring:1,
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/provisioning/olt', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/admin/provisioning/olt', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'OLT atualizada com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/provisioning/olt/create', state)
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'OLT criada com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} OLT</Typography>
                        <IconButton disabled={!auth.hasPerm("olt.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={() => {
                            if (confirm('Deseja excluir a OLT?')) {
                                delete_one('/admin/provisioning/olt', id).then(res => {
                                    notify.setNotify({ open: true, message: 'OLT deletada com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <EmpresaSelect
                        disabled={id}
                        sx={{ mb: 1, mt: 1 }}
                        value={state.empresa}
                        handler={(e) => setState({ ...state, empresa: e })}
                    />
                    <OltTypeSelect
                        value={state.type}
                        handler={(e) => setState({ ...state, type: e })}
                    />
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('name')}
                        helperText={error.msgError('name')}
                        label='Nome'
                        value={state.name}
                        name={'name'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('ip')}
                        helperText={error.msgError('ip')}
                        label='IP'
                        value={state.ip}
                        name={'ip'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb:1 }}
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        value={state.port}
                        name={'port'}
                        onChange={handleChange}
                    />
                    <TunnelSelect
                    sx={{ mb: 2 }}
                     value={state.interface}
                     empresa={state.empresa}
                     handler={(e) => setState({ ...state, interface: e })}
                     />

                    <OltProxySelect
                        sx={{ mt: 2 }}
                        value={state.proxy}
                        handler={(e) => setState({ ...state, proxy: e })}
                        empresa_id={state.empresa}
                        disabled={!state.empresa}
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                checked={state?.active}
                                onChange={(e) => setState({ ...state, active: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                       <FormControlLabel control={
                            <Checkbox
                            checked={state?.monitoring}
                                onChange={(e) => setState({ ...state, monitoring: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Monitorar"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
