import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import {IconButton, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../../components/Datatable/NotifyProvider';

import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';


export default function Log() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 OLIMPO - Logs"
    }, [])
    return (
        <>
        
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Logs de Monitoramento de Olts'
                endpoint='/admin/provisioning/log'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: "olt_name",
                        label: "OLT",
                    },
                    {
                        name: "info",
                        label: "Info",
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                ]}

            />
        </>
    )
}


